import { Container, Stack } from "@mui/material";
import { FeedEntryEmbeddedObjectType } from "api-shared";
import React, { useRef } from "react";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useFeedWithFilter } from "../../domain/feed";
import FeedEntry from "../feed/FeedEntry";
import FeedEntrySkeleton from "../feed/FeedEntrySkeleton";
import { useMeasureContext } from "../MeasureContext";

const FeedHistory = () => {
    const measure = useMeasureContext();
    const measureId = measure.id;

    const guardRef = useRef(null);

    const feedQuery = useFeedWithFilter({ id: measureId, type: FeedEntryEmbeddedObjectType.Measure });
    const feedEntries = feedQuery?.data?.items ?? [];

    if (measureId == null || (feedEntries.length > 0 && feedEntries[0].embeddedObjectId !== measureId)) {
        return <LoadingAnimation />;
    }

    return (
        <Container maxWidth="md" sx={{ p: 0 }}>
            <Stack spacing={1} role="feed">
                <>
                    {feedEntries.length > 0 ? feedEntries.map((feedEntry) => <FeedEntry key={feedEntry.id} feedEntry={feedEntry} />) : null}
                    {feedQuery.isLoading ? <FeedEntrySkeleton ref={guardRef} /> : null}
                </>
            </Stack>
        </Container>
    );
};

export default React.memo(FeedHistory);
