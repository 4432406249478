import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import { Button, CircularProgress, Stack } from "@mui/material";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { FileDto, ImageMimeTypes } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import DeleteFileDialog from "../../components/dialogues/DeleteFileDialog";
import UploadFileDialog from "../../components/dialogues/UploadFileDialog";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import NewLinkCallbackCell from "../../components/table/CellRenderer/LinkCallbackCell";
import TableDateCell from "../../components/table/CellRenderer/TableDateCell";
import TableHeaderCell from "../../components/table/CellRenderer/TableHeaderCell";
import TableTextCell from "../../components/table/CellRenderer/TableTextCell";
import TableUserCell from "../../components/table/CellRenderer/TableUserCell";
import { DateFormat } from "../../components/table/TableDateCell";
import TableIconButton from "../../components/table/TableIconButton";
import UncontrolledPaperTable from "../../components/table/UncontrolledTable/UncontrolledPaperTable";
import { useDeleteFile, useMeasureFiles, useUploadMeasureFile } from "../../domain/files";
import { useCurrentUserCanEditMeasure } from "../../domain/measure/detail";
import { useAllUsers } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { baseUrl } from "../../lib/api";
import { formatSize } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureFullHeightTab from "./MeasureFullHeightTab";

const getFileUrl = (baseUrl: string, path: string | null) => (path ? `${baseUrl}static/${path}` : "");
const getInlineFileUrl = (baseUrl: string, path: string | null) => (path ? `${baseUrl}static/inline/${path}` : "");

const PageSizeOptions = [25, 50, 100, 200];

const columnHelper = createColumnHelper<FileDto>();

const FileList = () => {
    const { t: translate } = useTranslation();

    const measure = useMeasureContext();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const users = useAllUsers();

    const filesQuery = useMeasureFiles({ measureId: measure.id });
    const uploadFileMutation = useUploadMeasureFile();
    const deleteFileMutation = useDeleteFile();

    const uploadDialog = useDialog();
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isValidUpload, setIsValidUpload] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<string>();
    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const handleUpload = () => {
        if (fileToUpload !== null) {
            uploadFileMutation.mutate({ measureId: measure.id, file: fileToUpload });
        }

        uploadDialog.close();
        setFileToUpload(null);
        setIsValidUpload(false);
    };

    const imageUrls: CarouselItem[] = useMemo(
        () =>
            filesQuery.data
                ?.filter((img) => img.mimetype && ImageMimeTypes.includes(img.mimetype))
                .map((img) => ({
                    imgLink: getFileUrl(baseUrl, img.hash),
                    displayName: `${img.filename} (${formatSize(img.size ?? 0)})`,
                })) ?? [],
        [filesQuery.data],
    );

    const handleFileClick = useCallback(
        (index: number) => {
            if (!filesQuery.isSuccess) {
                return;
            }
            const clickedFile = filesQuery.data[index];
            if (clickedFile === undefined) {
                return;
            }

            if (clickedFile.mimetype && ImageMimeTypes.includes(clickedFile.mimetype)) {
                const idx = imageUrls.findIndex((imgUrl) => clickedFile.hash && imgUrl.imgLink.includes(clickedFile.hash));
                setCurrentImage(idx);
            }
        },
        [filesQuery.data, filesQuery.isSuccess, imageUrls],
    );

    const renderNewFileName = useCallback(
        (props: CellContext<FileDto, string>) => {
            const isImage = props.row.original.mimetype && ImageMimeTypes.includes(props.row.original.mimetype);
            const isSupportedInlineFile = props.row.original.mimetype && props.row.original.mimetype === "application/pdf";
            if (isImage) {
                return <NewLinkCallbackCell onClick={handleFileClick} {...props} />;
            } else if (isSupportedInlineFile) {
                return (
                    <NewLinkCallbackCell
                        onClick={() => window.open(getInlineFileUrl(baseUrl, props.row.original.hash), "_blank")}
                        {...props}
                    />
                );
            } else {
                return <TableTextCell {...props} />;
            }
        },
        [handleFileClick],
    );

    const renderNewDate = useCallback((props: CellContext<FileDto, Date>) => <TableDateCell format={DateFormat.DateTime} {...props} />, []);

    const renderNewUser = useCallback((props: CellContext<FileDto, number>) => <TableUserCell {...props} users={users} />, [users]);

    const renderNewActions = useCallback(
        ({ cell }: CellContext<FileDto, string>) => (
            <>
                <TableIconButton title={translate(translationKeys.VDLANG_DOWNLOAD)} href={getFileUrl(baseUrl, cell.getValue())}>
                    <DownloadIcon />
                </TableIconButton>
                <TableIconButton
                    title={translate(translationKeys.VDLANG_FILES_COLUMN_DELETE)}
                    onClick={() => setFileToDelete(cell.getValue())}
                    disabled={!currentUserCanEditMeasure}
                >
                    <DeleteIcon />
                </TableIconButton>
            </>
        ),
        [currentUserCanEditMeasure, translate],
    );

    const newColumns = useMemo(
        () => [
            columnHelper.accessor((file: FileDto) => `${file.filename} (${formatSize(file.size ?? 0)})`, {
                header: TableHeaderCell,
                meta: { label: translate(translationKeys.VDLANG_FILES_COLUMN_FILENAME) },
                cell: renderNewFileName,
                minSize: 150,
                id: "name",
                sortingFn: "alphanumeric",
            }),
            columnHelper.accessor((file: FileDto) => new Date(file.createdAt ?? 0), {
                header: TableHeaderCell,
                meta: { label: translate(translationKeys.VDLANG_FILES_COLUMN_DATE) },
                cell: renderNewDate,
                id: "createdAt",
                sortingFn: "datetime",
            }),
            columnHelper.accessor("createdById", {
                header: TableHeaderCell,
                meta: { label: translate(translationKeys.VDLANG_FILES_COLUMN_USER) },
                cell: renderNewUser,
                id: "createdById",
                sortingFn: "alphanumeric",
            }),
            columnHelper.accessor("hash", {
                header: TableHeaderCell,
                meta: { label: translate(translationKeys.VDLANG_FILES_COLUMN_ACTIONS) },
                cell: renderNewActions,
                size: 96,
                id: "hash",
                enableSorting: false,
                enableResizing: false,
            }),
        ],
        [renderNewFileName, renderNewDate, renderNewUser, renderNewActions, translate],
    );

    if (!filesQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <MeasureFullHeightTab>
            <Stack sx={{ height: "100%" }} spacing={2}>
                <Stack direction="row" spacing={2}>
                    <UploadFileDialog
                        open={uploadDialog.isOpen}
                        onClose={uploadDialog.close}
                        onUpload={handleUpload}
                        file={fileToUpload ?? null}
                        updateFile={(file, isValid) => {
                            setFileToUpload(file);
                            setIsValidUpload(isValid);
                        }}
                        primaryDisabled={!isValidUpload}
                        translate={translate}
                    />
                    <DeleteFileDialog
                        item="file"
                        open={fileToDelete !== undefined}
                        onClose={() => setFileToDelete(undefined)}
                        file={filesQuery.data?.find((f) => f.hash === fileToDelete)}
                        onDelete={() =>
                            fileToDelete !== undefined && deleteFileMutation.mutate({ hash: fileToDelete, measureId: measure.id })
                        }
                        translate={translate}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!currentUserCanEditMeasure || uploadFileMutation.isLoading}
                        onClick={uploadDialog.open}
                        startIcon={<CloudUploadIcon />}
                    >
                        {translate(translationKeys.VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO)}
                    </Button>
                    {uploadFileMutation.isLoading && <CircularProgress variant="determinate" value={uploadFileMutation.progress} />}
                </Stack>

                <UncontrolledPaperTable
                    fullHeight
                    data={filesQuery.data}
                    columns={newColumns}
                    noDataText={translate(translationKeys.VDLANG_NO_FILES)}
                    itemName="files"
                    pageSizeOptions={PageSizeOptions}
                    defaultPageSize={50}
                />
                <Carousel onClose={() => setCurrentImage(null)} onChange={setCurrentImage} currentIndex={currentImage} items={imageUrls} />
            </Stack>
        </MeasureFullHeightTab>
    );
};

export default FileList;
