import { TypographyProps } from "@mui/material";
import { CellContext, flexRender } from "@tanstack/react-table";
import TableOverflowWrapper from "./TableOverflowWrapper";

interface TableTextCellProps<D extends object = Record<string, unknown>>
    extends CellContext<D, string>,
        Pick<TypographyProps, "align" | "className"> {
    title?: string | null;
    children?: any;
}

const TableTextCell = <D extends object = Record<string, unknown>>({ title, children, align, className, cell }: TableTextCellProps<D>) => {
    const content = cell.getValue() ?? (children as string);

    return content != null ? (
        <TableOverflowWrapper title={title} align={align} className={className}>
            {flexRender(content, cell.getContext())}
        </TableOverflowWrapper>
    ) : null;
};

export default TableTextCell;
