import { Button, Tooltip, Typography } from "@mui/material";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../../translations/main-translations";
import DecisionRequestMessage from "./DecisionRequestMessage";
import DecisionStep from "./DecisionStep";

interface IDecisionRequestProps {
    disabled?: boolean;
    isRequisiteGateTaskCompleted: boolean;
    decider: UserDto;
    requestDecision: () => void;
    requestedDeciderId: number | null;
    translate: TFunction;
    currentUser: UserDto | null;
    requestedAt: Date | null;
    requesterId: number | null;
    users: UserDto[];
    deciderIsEditor: boolean;
}

const DecisionRequest = ({
    decider,
    disabled,
    isRequisiteGateTaskCompleted,
    requestDecision,
    translate,
    requestedDeciderId,
    currentUser,
    requestedAt,
    requesterId,
    users,
    deciderIsEditor,
}: IDecisionRequestProps) => {
    const tooltipTitle = !deciderIsEditor ? translate(translationKeys.VDLANG_COMPLETION_HINT_DECIDER_INVALID) : "";
    return (
        <DecisionStep item xs={12} md={3}>
            <Tooltip
                title={
                    disabled && !isRequisiteGateTaskCompleted
                        ? translate(translationKeys.VDLANG_COMPLETION_HINT_PREDECESSORS)
                        : tooltipTitle
                }
                placement="top"
            >
                <span>
                    <Button onClick={requestDecision} disabled={disabled} variant="contained">
                        {translate(requestedDeciderId === decider.id ? "decision_type_1_again" : "decision_type_1_action")}
                    </Button>
                </span>
            </Tooltip>
            {currentUser != null && requestedAt != null && requesterId != null && requestedDeciderId != null ? (
                <Typography variant="caption" color="textSecondary">
                    <DecisionRequestMessage
                        currentUser={currentUser}
                        requestedAt={requestedAt}
                        requesterId={requesterId}
                        requestedDeciderId={requestedDeciderId}
                        translate={translate}
                        users={users}
                    />
                </Typography>
            ) : null}
        </DecisionStep>
    );
};

export default DecisionRequest;
