import { Paper, styled } from "@mui/material";
import UncontrolledTable, { UncontrolledTableProps } from "./UncontrolledTable";

const FullHeightContainer = styled(Paper)({
    height: "100%",
});

const UncontrolledPaperTable = <D extends object = Record<string, unknown>>(tableProps: UncontrolledTableProps<D>) => {
    const Container = tableProps.fullHeight ? FullHeightContainer : Paper;
    return (
        <Container>
            <UncontrolledTable {...tableProps} />
        </Container>
    );
};

export default UncontrolledPaperTable;
