import CloseIcon from "@mui/icons-material/CloseRounded";
import { IconButton, styled } from "@mui/material";
import { TFunction } from "i18next";
import { useMemo } from "react";
import BaseDialog, { IBaseDialogProps, baseDialogClasses } from "../../../../components/dialogues/BaseDialog";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import { useEffectCategoryHistory } from "../../../../domain/effect-category";
import { translationKeys } from "../../../../translations/main-translations";
import CalculationHistoryTable from "./CalculationHistoryTable";

const DenseDialog = styled(BaseDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.title}`]: {
        padding: theme.spacing(1.5, 3),
    },
    [`& .${baseDialogClasses.actions}`]: {
        padding: theme.spacing(0.25, 0.5),
    },
}));

export interface ICalculationHistoryDialogProps
    extends Omit<IBaseDialogProps, "actions" | "translate" | "title">,
        Partial<Pick<IBaseDialogProps, "title">> {
    translate: TFunction;
    categoryId: number;
}

const CalculationHistoryDialog = ({ translate, title, categoryId, onClose, ...other }: ICalculationHistoryDialogProps) => {
    const effectCategoryHistoryEntriesQuery = useEffectCategoryHistory(categoryId);

    const menuActions = useMemo(
        () => [
            <IconButton key="close" aria-label={translate(translationKeys.VDLANG_CLOSE)} onClick={onClose}>
                <CloseIcon />
            </IconButton>,
        ],
        [translate, onClose],
    );

    return (
        <DenseDialog
            title={translate("history")}
            maxWidth="lg"
            menuActions={menuActions}
            onClose={onClose}
            disableScrollLock={true}
            disableContentPadding
            {...other}
        >
            {effectCategoryHistoryEntriesQuery.isSuccess ? (
                <CalculationHistoryTable historyEntries={effectCategoryHistoryEntriesQuery.data.items} categoryId={categoryId} />
            ) : (
                <LoadingAnimation />
            )}
        </DenseDialog>
    );
};

export default CalculationHistoryDialog;
