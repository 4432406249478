import { Link as StandardLink, Typography } from "@mui/material";
import { ReactNode } from "react";

import { CellContext, flexRender } from "@tanstack/react-table";
import Tooltip from "../../Tooltip";

interface LinkCallbackCellProps<D> extends CellContext<D, string> {
    /**
     * Cell value will be used as link text, falling back to children when the cell value is nullish.
     *
     * Additionally, when the value differs from the provided children, the children will be renderend outside the button element so that
     * additional content can be rendered
     *
     * @type {ReactNode}
     * @memberof LinkCallbackCellProps
     */
    children?: ReactNode;
    onClick?: (index: number) => void;
    className?: string;
}

const LinkCallbackCell = <D extends object = Record<string, unknown>>({
    renderValue,
    children,
    onClick = () => {},
    row,
    className,
    cell,
}: LinkCallbackCellProps<D>) => {
    const linkLabel = renderValue() ?? children;
    return (
        <div className={className}>
            <Tooltip title={linkLabel} placement="right" onlyOverflowing>
                <Typography variant="body2" noWrap>
                    <StandardLink onClick={() => onClick(row.index)} sx={{ cursor: "pointer" }} fontSize="inherit" variant="body2">
                        {flexRender(linkLabel, cell.getContext())}
                    </StandardLink>
                </Typography>
            </Tooltip>
            {linkLabel !== children ? children : null}
        </div>
    );
};

export default LinkCallbackCell;
