import { createColumnHelper } from "@tanstack/react-table";
import { AclPermissions, DecisionResult, GateStatus, GateTaskType, HistoryEventType, MeasureHistoryDto, UNKNOWN_USER } from "api-shared";
import { camelCase } from "lodash";
import moment, { MomentInput } from "moment-timezone";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import TableHeaderCell from "../../components/table/CellRenderer/TableHeaderCell.tsx";
import TableTextCell from "../../components/table/CellRenderer/TableTextCell";
import UncontrolledPaperTable from "../../components/table/UncontrolledTable/UncontrolledPaperTable.tsx";
import { useDecisions } from "../../domain/decision";
import { useGroups } from "../../domain/group.ts";
import { useMeasureConfig, useMeasureConfigs } from "../../domain/measure-config";
import { useProcessHistory } from "../../domain/process-history";
import { useAllUsers } from "../../domain/users";
import { useLanguage } from "../../hooks/useLanguage.ts";
import useTimezone from "../../hooks/useTimezone";
import { reportError } from "../../infrastructure/sentry";
import { formatUserFromId } from "../../lib/formatters";
import { isId, isMatching, replaceImage } from "../../lib/history";
import { replaceMentionUsers } from "../../lib/mention";
import { translateFromProperty } from "../../lib/translate.ts";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureFullHeightTab from "./MeasureFullHeightTab";

type MeasureHistoryTableName =
    | "measure"
    | "file"
    | "measure_value"
    | "gate_task"
    | "sub_task"
    | "acl_user"
    | "acl_group"
    | "effect_category"
    | "decision";

const zRange = z.object({
    start: z.string().nullish(),
    end: z.string().nullish(),
});

type HistoryRow = {
    user: string;
    time: string;
    entry: string;
    date: string;
};

const formatDateRange = (range: unknown) => {
    const parseResult = zRange.safeParse(range);
    if (!parseResult.success) {
        return JSON.stringify(range);
    }
    const { start, end } = parseResult.data;
    return `${start ?? ""} - ${end ?? ""}`;
};

const isDecisionValueFilled = ({ tableName, attribute }: MeasureHistoryDto) => tableName === "decision" && attribute === "is_approved";

const isVisibleEntry = (item: MeasureHistoryDto) => !isDecisionValueFilled(item);

const getLatestItem = (entries: MeasureHistoryDto[], predicate: (x: MeasureHistoryDto) => boolean, timeBarrier: Date | string) => {
    const matches = entries
        // consider only those matching the given predicate
        .filter(predicate)
        // and only those that happened before the give time barrier
        .filter((entry) => new Date(entry.datetime) <= new Date(timeBarrier))
        // sorting by descending id gives higher resolution than sorting by timestamps
        .sort((a, b) => b.id - a.id);
    return matches[0];
};

const sortByDateAndId = (a: MeasureHistoryDto, b: MeasureHistoryDto) => {
    // Sort primarily by timestamps.
    // Timestamps are reduced to a resolution of 1 second for comparison.
    // In case of a tie (i.e., when two timestamps are exactly the same up to the second), use 'id' as a tie-breaker.
    // 'id' by itself doesn't work for sorting as the entries may exist with larger id but smaller timestamp (this is
    // often the case when may history entries are created by migrations)
    const aDateInSeconds = Math.floor(new Date(a.datetime).getTime() / 1000);
    const bDateInSeconds = Math.floor(new Date(b.datetime).getTime() / 1000);

    const dateCriterion = bDateInSeconds - aDateInSeconds;
    const idCriterion = b.id - a.id;

    return dateCriterion !== 0 ? dateCriterion : idCriterion;
};

interface HistoryMatchCriteria {
    attribute?: MeasureHistoryDto["attribute"];
    previousValue?: MeasureHistoryDto["previousValue"];
    newValue?: MeasureHistoryDto["newValue"];
}

interface VisibleMeasureHistoryEvent extends HistoryMatchCriteria {
    ignore?: false | undefined;
    type: HistoryEventType;
    key: translationKeys | ((item: MeasureHistoryDto) => translationKeys);
    // data passed to translate
    getData?: (item: MeasureHistoryDto) => Record<string, unknown> | void;
}

interface HiddenHistoryEvent extends HistoryMatchCriteria {
    ignore: true;
}

type MeasureHistoryEvent = HiddenHistoryEvent | VisibleMeasureHistoryEvent;

const columnHelper = createColumnHelper<HistoryRow>();

const getEntry = (
    item: MeasureHistoryDto,
    {
        translate,
        userName,
        groupName,
        otherDeciderName,
        gateName,
        formatDate,
        formatTime,
        attributeName,
        formatNewValue,
        formatPreviousValue,
        getGateCompletedMessage,
        measureConfig,
        decisionApproved,
        getMesureConfigName,
    }: any,
) => {
    const getGateDateData = (item: MeasureHistoryDto) => ({
        gateName: gateName(item),
        date: formatDate(moment.utc(item.newValue)),
        time: formatTime(moment.utc(item.newValue)),
    });
    const getGateData = (item: MeasureHistoryDto) => ({
        gateName: gateName(item),
        newValue: String(item.newValue),
        previousValue: String(item.previousValue),
    });
    const getDeciderMessage = (item: MeasureHistoryDto) =>
        item.newValue === null
            ? {
                  key: translationKeys.VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER_REMOVE,
                  getData: (item: MeasureHistoryDto) => ({
                      userName: userName(item.previousValue),
                  }),
              }
            : {
                  key: translationKeys.VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER,
                  getData: (item: MeasureHistoryDto) => ({
                      userName: userName(item.newValue),
                  }),
              };

    const events: Record<MeasureHistoryTableName, MeasureHistoryEvent[]> = {
        file: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_FILE_INSERT,
                getData: (item: MeasureHistoryDto) => ({
                    newValue: String(item.newValue),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_FILE_DELETE,
                getData: (item: MeasureHistoryDto) => ({
                    previousValue: String(item.previousValue),
                }),
            },
        ],
        measure: [
            {
                type: HistoryEventType.INSERT,
                key:
                    item.previousValue != null && item.previousValue.length > 0
                        ? translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_COPY
                        : translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_INSERT,
                getData: (item: MeasureHistoryDto) => ({
                    newValue: String(item.newValue),
                    previousValue: String(item.previousValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "title",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_TITLE,
                getData: (item: MeasureHistoryDto) => ({
                    previousValue: String(item.previousValue),
                    newValue: String(item.newValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "1",
                newValue: "0",
                key: (item: MeasureHistoryDto) =>
                    otherDeciderName(item) != null && !decisionApproved
                        ? translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_ON_BEHALF_0_1
                        : translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_0_1,
                getData: (item: MeasureHistoryDto) => {
                    const deciderName = otherDeciderName(item);
                    if (deciderName != null) {
                        return {
                            userName: deciderName,
                        };
                    }
                },
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "1",
                newValue: "2",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_1_2,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "2",
                newValue: "0",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_2_0,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                newValue: "1",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_TO_1,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "visibility",
                previousValue: "1",
                newValue: "2",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_1_2,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "visibility",
                previousValue: "2",
                newValue: "1",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_2_1,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "visibility",
                previousValue: "0",
                newValue: "1",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_1,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "visibility",
                previousValue: "0",
                newValue: "2",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_2,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "visibility",
                newValue: "0",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_TO_0,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "assigned_to",
                key:
                    item.newValue === null
                        ? translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO_TO_EMPTY
                        : translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO,
                getData: (item: MeasureHistoryDto) => ({
                    newValue: userName(item.newValue),
                    previousValue: userName(item.previousValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "currency_id",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_CURRENCY,
                getData: (item: MeasureHistoryDto) => ({
                    newValue: formatNewValue(item),
                    previousValue: formatPreviousValue(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "measure_config_id",
                key: translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VALUESTREAM,
                getData: (item: MeasureHistoryDto) => ({
                    newValue: getMesureConfigName(Number(item.newValue)),
                    previousValue: getMesureConfigName(Number(item.previousValue)),
                }),
            },
        ],
        acl_user: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_READ,
                newValue: String(AclPermissions.Read),
                previousValue: "",
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_UPDATE,
                newValue: String(AclPermissions.Update),
                previousValue: "",
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_READ,
                newValue: "",
                previousValue: String(AclPermissions.Read),
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_UPDATE,
                newValue: "",
                previousValue: String(AclPermissions.Update),
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.attribute),
                }),
            },
        ],
        acl_group: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_READ,
                newValue: String(AclPermissions.Read),
                previousValue: "",
                getData: (item: MeasureHistoryDto) => ({
                    groupName: groupName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_UPDATE,
                newValue: String(AclPermissions.Update),
                previousValue: "",
                getData: (item: MeasureHistoryDto) => ({
                    groupName: groupName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_READ,
                newValue: "",
                previousValue: String(AclPermissions.Read),
                getData: (item: MeasureHistoryDto) => ({
                    groupName: groupName(item.attribute),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_UPDATE,
                newValue: "",
                previousValue: String(AclPermissions.Update),
                getData: (item: MeasureHistoryDto) => ({
                    groupName: groupName(item.attribute),
                }),
            },
        ],
        measure_value: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_INSERT,
                getData: (item: MeasureHistoryDto) => ({
                    attributeName: attributeName(item),
                    newValue: formatNewValue(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                previousValue: null,
                key: translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_INSERT,
                getData: (item: MeasureHistoryDto) => ({
                    attributeName: attributeName(item),
                    newValue: formatNewValue(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                newValue: null,
                key: translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE_TO_NULL,
                getData: (item: MeasureHistoryDto) => ({
                    previousValue: formatPreviousValue(item),
                    attributeName: attributeName(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                newValue: "",
                key: translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE_TO_NULL,
                getData: (item: MeasureHistoryDto) => ({
                    previousValue: formatPreviousValue(item),
                    attributeName: attributeName(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE,
                getData: (item: MeasureHistoryDto) => ({
                    previousValue: formatPreviousValue(item),
                    attributeName: attributeName(item),
                    newValue: formatNewValue(item),
                }),
            },
        ],
        gate_task: [
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "1000",
                newValue: "1002",
                ...getGateCompletedMessage(item),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "1002",
                newValue: "1000",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1002_1000,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "assigned_to",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO,
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.newValue),
                    gateName: gateName(item),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "start_date",
                previousValue: "",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE_FROM_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                    newValue: String(item.newValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "start_date",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE,
                getData: getGateData,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "duedate",
                previousValue: "",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE_FROM_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                    newValue: String(item.newValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "duedate",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE,
                getData: getGateData,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "start_remind_at",
                newValue: "",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT_TO_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                // this item causes warnings of momentjs, because it tries to format arbitrary values as date, which is deprecated
                type: HistoryEventType.UPDATE,
                attribute: "start_remind_at",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT,
                getData: getGateDateData,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "remind_at",
                newValue: "",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT_TO_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                // this item causes warnings of momentjs, because it tries to format arbitrary values as date, which is deprecated
                type: HistoryEventType.UPDATE,
                attribute: "remind_at",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT,
                getData: getGateDateData,
            },
            {
                type: HistoryEventType.DELETE,
                attribute: "assigned_to",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO_TO_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.previousValue),
                    gateName: gateName(item),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                attribute: "duedate",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_DUEDATE,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                attribute: "start_date",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_START_DATE,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                type: HistoryEventType.DELETE,
                attribute: "remind_at",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT_TO_EMPTY,
                getData: (item: MeasureHistoryDto) => ({
                    gateName: gateName(item),
                }),
            },
            {
                // this item causes warnings of momentjs, because it tries to format arbitrary values as date, which is deprecated
                type: HistoryEventType.DELETE,
                attribute: "start_remind_at",
                key: translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT,
                getData: getGateDateData,
            },
        ],
        sub_task: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_INSERT,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "1",
                newValue: "2",
                key: translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_1_2,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                newValue: "0",
                key: translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_TO_0,
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "status",
                previousValue: "2",
                newValue: "1",
                key: translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_2_1,
            },
        ],
        effect_category: [
            {
                type: HistoryEventType.INSERT,
                key: translationKeys.VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_INSERT,
            },
            {
                type: HistoryEventType.DELETE,
                key: translationKeys.VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_DELETE,
            },
        ],
        decision: [
            // the only decision event that should be shown
            // positive/negative decision is shown as part of the gate completed message
            {
                type: HistoryEventType.UPDATE,
                attribute: "requested_decider",
                key: translationKeys.VDLANG_PROCESS_HISTORY_DECISION_UPDATE_REQUESTED_DECIDER,
                getData: (item: MeasureHistoryDto) => ({
                    userName: userName(item.newValue),
                }),
            },
            {
                type: HistoryEventType.UPDATE,
                attribute: "decider",
                ...getDeciderMessage(item),
            },
            {
                attribute: "is_approved",
                ignore: true,
            },
        ],
    };

    if (!Object.keys(events).includes(item.tableName)) {
        return null;
    }

    const entry = events[item.tableName as MeasureHistoryTableName].find(
        (event) =>
            (event.ignore === true || event.type === item.type) &&
            isMatching(event.attribute, item.attribute) &&
            isMatching(event.previousValue, item.previousValue ?? "") &&
            isMatching(event.newValue, item.newValue ?? ""),
    );

    if (entry == null) {
        // maybe a case has been forgotten, or there is bad history data. Report this, so it can be investigated
        const { tableName, type, attribute } = item;
        reportError(new Error("Unknown history entry"), {
            tags: { history: tableName },
            extras: {
                tableName,
                type: String(type),
                attribute,
                previousValue: String(item.previousValue),
                newValue: String(item.newValue),
            },
        });
        // this entry will be hidden
        return null;
    }

    if (entry.ignore) {
        return null;
    }

    return translate(
        `process_history.${typeof entry.key === "function" ? entry.key(item) : entry.key}`,
        {
            processName: translate(measureConfig.name),
            ...entry.getData?.(item),
        },
        { renderInnerHtml: false },
    );
};

const History = () => {
    const measure = useMeasureContext();
    const measureId = measure.id;
    const measureConfig = useMeasureConfig(measure);
    const measureConfigs = useMeasureConfigs();
    const history = useProcessHistory(measureId);
    const users = useAllUsers();
    const groups = useGroups();
    const decision = useDecisions(measureId).data?.find((decision) => decision.isApproved);
    const decisionApproved = decision !== undefined;

    const { t: translate } = useTranslation();
    const { formatDate, formatTime } = useTimezone();
    const language = useLanguage();

    const attributeName = ({ attribute }: MeasureHistoryDto) => {
        if (attribute == null || isId(+attribute)) {
            // dont try to translate ids
            return "";
        }
        // use camelCase here because of start_date and end_date
        return attribute.length > 0 ? translate([attribute, camelCase(attribute)]) : "";
    };

    const userName = (value: string | number | null) => {
        if (value == null || !isId(+value)) {
            // do not try to format non-ids as username
            return "";
        }
        const formattingOptions = { translate: translate, warnOnMissingUser: false };
        let userName = formatUserFromId(+value, users, formattingOptions);
        if (userName === UNKNOWN_USER) {
            // user id not found, fall back to measure creator
            userName = formatUserFromId(measure.createdById, users, formattingOptions);
        }
        return userName;
    };

    const groupName = (value?: string | number | null) => {
        if (value == null || !isId(Number(value))) {
            return "";
        }
        const group = groups.data?.find((group) => group.id === Number(value));
        return group !== undefined ? translateFromProperty(group, "name", language) : translate(translationKeys.VDLANG_UNKNOWN_GROUP);
    };

    // If decision was made on behalf of another user, get their username for the history entry
    const otherDeciderName = (measureHistory: MeasureHistoryDto) => {
        if (history.data !== undefined) {
            // Get the decider at the time of the entry by finding the corresponding update entry via timestamps
            // This is an arguably crude but straightforward solution for mapping the required data
            const diffDate = new Date(measureHistory.datetime);
            const [deciderUpdateEntry] = history.data
                .filter((mh) => mh.parentId === measureHistory.parentId && mh.attribute === "decider")
                .toSorted((a, b) => {
                    const distanceA = Math.abs(diffDate.getTime() - new Date(a.datetime).getTime());
                    const distanceB = Math.abs(diffDate.getTime() - new Date(b.datetime).getTime());
                    return distanceA - distanceB;
                });
            if (deciderUpdateEntry !== undefined) {
                const { newValue: deciderId } = deciderUpdateEntry;
                return deciderId !== null && Number(deciderId) !== measureHistory.userId ? userName(Number(deciderId)) : null;
            }
            return null;
        }
    };

    const formatValue = (item: MeasureHistoryDto, valueIn: string | null): string => {
        if (valueIn == null) {
            return "";
        }

        // Replace mention tokens with actual user names
        let value: string | { start: string; end: string } = replaceMentionUsers(users, valueIn, translate);

        // try to convert string to other datatypes
        try {
            if (value.substring(0, 1) === "{") {
                // try to parse JSON object
                value = JSON.parse(value);
            }
        } catch {
            // parsing failed, continue with raw value
        }

        if (typeof value === "object") {
            value = formatDateRange(value);
        } else if (item.attribute === "is_approved") {
            // save in tmp variable to aid compiler (otherwise complains about undefined not being assignable to value)
            const decisionResultLabel = translate(`decision_result_${value}`);
            value = decisionResultLabel;
        } else if (typeof value === "string" && item.attribute === "decider") {
            value = userName(value);
        } else if (item.attribute.endsWith("pl_start") || item.attribute.endsWith("pl_end")) {
            value = formatDate(value as MomentInput);
        }

        // replace image in markdown
        value = replaceImage(value);

        if (typeof value === "string") {
            value = `"${value}"`;
        }

        return value;
    };

    const formatPreviousValue = (item: MeasureHistoryDto) => formatValue(item, item.previousValue);

    const formatNewValue = (item: MeasureHistoryDto) => formatValue(item, item.newValue);

    const getGateTaskConfigForGateTask = (gateTaskId?: number | null) => {
        const gateTask = measure.gateTasks.find(({ id }) => id === gateTaskId);
        return measureConfig?.gateTaskConfigs.find(({ id }) => id === gateTask?.gateTaskConfigId);
    };

    const getMesureConfigName = (mesaureConfigId?: number | null) => {
        const measureConfig = measureConfigs.find(({ id }) => id === mesaureConfigId);
        return measureConfig ? translate(measureConfig.name) : mesaureConfigId;
    };

    const gateName = (item: MeasureHistoryDto) => {
        const gateTaskConfig = getGateTaskConfigForGateTask(item.dataId);
        return gateTaskConfig !== undefined ? translate(gateTaskConfig.name) : "";
    };

    const getGateCompletedMessage = (item: MeasureHistoryDto) => {
        const data = {
            gateName: gateName(item),
            result: "",
        };
        const gateTaskConfig = getGateTaskConfigForGateTask(item.dataId);

        let key = translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002;
        if (
            !history.isSuccess &&
            gateTaskConfig?.type === GateTaskType.Decision &&
            item.attribute === "status" &&
            Number(item.newValue) === GateStatus.STATUS_COMPLETED
        ) {
            key = translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002_DECISION;

            const latestDecisionChangeItem = getLatestItem(history.data ?? [], isDecisionValueFilled, item.datetime);
            const resultKey = `decision_result_${latestDecisionChangeItem?.newValue ?? DecisionResult.RESULT_IMPLEMENT}`;
            data.result = translate(resultKey);
        }
        return { key, getData: () => data };
    };

    const toRow = (item: MeasureHistoryDto, index: number, array: MeasureHistoryDto[]) => {
        const rowContents = {
            date: formatDate(item.datetime),
            user: userName(item.userId),
            group: groupName(item.attribute),
            time: formatTime(item.datetime),
            entry: getEntry(item, {
                translate,
                userName,
                groupName,
                otherDeciderName,
                gateName,
                formatDate,
                formatTime,
                attributeName,
                formatNewValue,
                formatPreviousValue,
                getGateCompletedMessage,
                measureConfig,
                decisionApproved,
                getMesureConfigName,
            }),
        };

        // compare only on date level
        // check for set date because formatDate returns today in undefined case which will lead empty first row if change was today
        const sameDate = array[index - 1]?.datetime !== undefined && rowContents.date === formatDate(array[index - 1]?.datetime);

        if (!sameDate) {
            return rowContents;
        }

        rowContents.date = "";

        if (item.userId === array[index - 1]?.userId) {
            rowContents.user = "";
        }

        return rowContents;
    };

    const columns = useMemo(() => {
        return (
            [
                ["date", translationKeys.VDLANG_HISTORY_COLUMN_DATE],
                ["user", translationKeys.VDLANG_HISTORY_COLUMN_USER],
                ["time", translationKeys.VDLANG_HISTORY_COLUMN_TIME],
                ["entry", translationKeys.VDLANG_HISTORY_COLUMN_ENTRY],
            ] as const
        ).map(([name, translation]) =>
            columnHelper.accessor(name, {
                header: TableHeaderCell,
                meta: { label: translate(translation) },
                id: name,
                cell: TableTextCell,
                size: name === "entry" ? 150 : 50,
            }),
        );
    }, [translate]);

    if (measureId == null || !history.isSuccess) {
        return <LoadingAnimation />;
    }

    const data = history.data
        .filter(isVisibleEntry)
        .toSorted(sortByDateAndId)
        .map(toRow)
        // hide entries, that could not be resolved
        .filter(({ entry }) => entry != null);

    return (
        <MeasureFullHeightTab>
            <UncontrolledPaperTable
                fullHeight
                data={data}
                columns={columns}
                disableSorting
                noDataText=""
                pageSizeOptions={[25, 50, 100, 200, 500]}
                defaultPageSize={50}
            />
        </MeasureFullHeightTab>
    );
};

export default React.memo(History);
